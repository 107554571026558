@font-face {
  font-family: mulish-black;
  src: url(../static/fonts/Avenir-Black-03.ttf);
  font-display: swap;
}
 @font-face {
  font-family: mulish-black-italic;
  src: url(../static/fonts/Mulish-BlackItalic.ttf);
  font-display: swap;
}
@font-face {
  font-family: mulish-bold;
  src: url(../static/fonts/Avenir-Heavy-05.ttf);
  font-display: swap;
}
@font-face {
  font-family: mulish-bold-italic;
  src: url(../static/fonts/Mulish-BoldItalic.ttf);
  font-display: swap;
}
@font-face {
  font-family: mulish-extra-bold;
  src: url(../static/fonts/Mulish-ExtraBold.ttf);
  font-display: swap;
}
@font-face {
  font-family: mulish-extra-bold-italic;
  src: url(../static/fonts/Mulish-ExtraBoldItalic.ttf);
  font-display: swap;
}
@font-face {
  font-family: mulish-extra-light;
  src: url(../static/fonts/Mulish-ExtraLight.ttf);
  font-display: swap;
}
@font-face {
  font-family: mulish-extra-light-italic;
  src: url(../static/fonts/Mulish-ExtraLightItalic.ttf);
  font-display: swap;
}
@font-face {
  font-family: mulish-italic;
  src: url(../static/fonts/Mulish-Italic.ttf);
  font-display: swap;
}
@font-face {
  font-family: mulish-light;
  src: url(../static/fonts/Mulish-Light.ttf);
  font-display: swap;
}
@font-face {
  font-family: mulish-light-italic;
  src: url(../static/fonts/Mulish-LightItalic.ttf);
  font-display: swap;
}
@font-face {
  font-family: Avenir-Medium-09;
  src: url(../static/fonts/Mulish-Medium.ttf);
  font-display: swap;
}
@font-face {
  font-family: mulish-medium-italic;
  src: url(../static/fonts/Mulish-MediumItalic.ttf);
  font-display: swap;
} 
@font-face {
  font-family: mulish-regular;
  src: url(../static/fonts/Avenir-Medium-09.ttf);
  font-display: swap;
}
 @font-face {
  font-family: mulish-semi-bold;
  src: url(../static/fonts/Avenir-HeavyOblique-06.ttf);
  font-display: swap;
}
@font-face {
  font-family: mulish-semi-bold-italic;
  src: url(../static/fonts/Mulish-SemiBoldItalic.ttf);
  font-display: swap;
} 
