.slider-container {
  width: 100%;
  height: 100%;
}

.carousel-style {
  width: 100%;
  height: 100% !important;
}

.carousel-root {
  width: 100%;
  height: 100% !important;
}

.carousel-slider {
  width: 100%;
  height: 100% !important;
}

.slider-wrapper {
  width: 100%;
  height: 100% !important;
}

.slider {
  width: 100%;
  height: 100% !important;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  text-align: center;
}

.disableButton {
  background-color: rgba(146, 143, 143, 0.521) !important;
  border-color: rgba(167, 163, 163, 0.452) !important;
  cursor: not-allowed !important;
}
